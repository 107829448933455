import './styles.scss';
import {
  Project,
  ProjectModuleType,
  ProjectsDocument,
  ProjectsQuery,
  ProjectsQueryVariables,
  useEditProjectMutation,
  useGetProjectByIdQuery,
  useProjectCollaboratorsCreateManyMutation,
  useProjectCollaboratorsDeleteManyMutation,
  useProjectCollaboratorsFindManyPaginatedQuery,
  useProjectCollaboratorsUpdateRoleMutation,
} from 'generated/graphql';
import { IProjectPath } from 'interfaces/routes';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionItem,
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { ProjectAuthenticationSettings } from './components/ProjectAuthenticationSettings/ProjectAuthenticationSettings';
import ProjectJobRolesOverview from './components/ProjectJobRolesOverview/ProjectJobRolesOverview';
import ProjectLanguageForm, { IProjectLanguageFormSubmitValues } from './components/ProjectLanguageForm';
import ProjectOverviewForm, { IProjectOverviewFormSubmitValues } from './components/ProjectOverviewForm';
import ProjectReportsForm from './components/ProjectReportsForm';
import Stage from './components/Stages/Stage';
import ProjectBar from './ProjectBar';
import {
  ProjectLanguageInfo,
  ProjectOverviewInfo,
  ProjectReportsInfo,
} from './ProjectInfo';

const ProjectEdit: FC = () => {
  const { handleMsgType } = useNotification();
  const { projectId, projectRequestId } = useParams() as IProjectPath;
  const parsedProjectId = Number(projectId);
  const parsedProjectRequestId = Number(projectRequestId);
  const [editProjectOverviewFormActive, setEditProjectOverviewFormActive] =
    useState(false);
  const [editProjectLanguageFormActive, setEditProjectLanguageFormActive] =
    useState(false);

  const getProjectByIdResponse = useGetProjectByIdQuery({
    variables: { id: parsedProjectId },
    fetchPolicy: 'network-only',
    onError: error =>
      handleMsgType({
        type: TNotification.error,
        title: error?.message,
      }),
  });

  const projectCollaborators = useProjectCollaboratorsFindManyPaginatedQuery({
    skip: !parsedProjectId,
    variables: {
      args: {
        projectId: parsedProjectId,
      },
      paginate: {
        size: 1000,
      },
    },
  });

  const [createProjectCollaborators] =
    useProjectCollaboratorsCreateManyMutation({});
  const [updateRole] = useProjectCollaboratorsUpdateRoleMutation({});
  const [deleteProjectCollaborators] =
    useProjectCollaboratorsDeleteManyMutation({});

  const [editProjectMutation, { loading }] = useEditProjectMutation({
    update(cache, { data }) {
      const project = data?.project as Project;
      if (project) {
        const cachedData = cache.readQuery<
          ProjectsQuery,
          ProjectsQueryVariables
        >({
          query: ProjectsDocument,
        });
        if (cachedData) {
          cache.writeQuery({
            query: ProjectsDocument,
            data: {
              projects: cachedData.projects.map(p =>
                p.id === project.id ? project : p
              ),
            },
          });
        }
      }
    },
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        title: 'Project successfully updated',
      });
    },
    onError: error =>
      handleMsgType({
        type: TNotification.error,
        title: error?.message,
      }),
  });

  const handleEditProjectOverview = async ({
    name,
    productSolution,
    companyId,
    requireSheetsExport,
    collaborators,
    removedCollaborators,
  }: IProjectOverviewFormSubmitValues) => {
    const allCollaborators =
      projectCollaborators.data?.collaborators?.data || [];
    const toSync = collaborators.map(collaborator => {
      const found = allCollaborators.find(
        pc => pc.userId === collaborator.userId
      );
      return { ...collaborator, id: found?.id || null };
    });
    const toRemove = removedCollaborators.map(collaborator => {
      const found = allCollaborators.find(
        pc => pc.userId === collaborator.userId
      );
      return { ...collaborator, id: found?.id || null };
    });
    const toUpdate = toSync.filter(collaborator => collaborator.id);
    const toCreate = toSync.filter(collaborator => !collaborator.id);
    console.log(collaborators, removedCollaborators);
    console.log(allCollaborators);
    console.log(toUpdate, toCreate, toRemove);

    await Promise.all([
      editProjectMutation({
        variables: {
          name,
          ...(productSolution && { productSolution: productSolution }),
          companyId: Number(companyId),
          id: parsedProjectId,
          requireSheetsExport,
        },
      }),
      toCreate.length
        ? createProjectCollaborators({
            variables: {
              collaborators: toCreate.map(collaborator => ({
                actorRole: collaborator.role,
                userId: collaborator.userId,
                projectId: parsedProjectId,
              })),
            },
          })
        : null,
      await Promise.all(
        toUpdate.map(collaborator =>
          updateRole({
            variables: {
              collaboratorId: collaborator.id as number,
              actorRole: collaborator.role,
            },
          })
        )
      ),
      removedCollaborators.length
        ? deleteProjectCollaborators({
            variables: {
              collaborators: toRemove
                .map(collaborator => collaborator.id)
                .filter(x => !!x) as Array<number>,
            },
          })
        : null,
    ]);
    projectCollaborators.refetch();
    setEditProjectOverviewFormActive(false);
  };

  const handleEditProjectLocales = ({
    locales,
  }: IProjectLanguageFormSubmitValues) => {
    const project = getProjectByIdResponse?.data?.project;
    if (!project) {
      handleMsgType({
        type: TNotification.error,
        title: 'Could not save project languages at this time.',
      });
      return;
    }
    if (!locales.length) {
      handleMsgType({
        type: TNotification.error,
        title: 'Select at least one language for this project',
      });
      return;
    }
    editProjectMutation({
      variables: {
        name: project.name,
        id: parsedProjectId,
        companyId: Number(project.company?.id),
        locales,
      },
      onCompleted: () => setEditProjectLanguageFormActive(false),
    });
  };

  if (getProjectByIdResponse.loading) {
    return (
      <div className="project-loader-wrapper">
        <Loader variant="bubbles" />
      </div>
    );
  }

  if (getProjectByIdResponse.error) {
    return null;
  }

  if (getProjectByIdResponse.data && getProjectByIdResponse.data.project) {
    const project = getProjectByIdResponse.data.project;
    const collaborators = projectCollaborators.data?.collaborators?.data;
    return (
      <div className="project">
        <div className="project-edit">
          <ProjectBar
            project={project}
            projectRequestId={parsedProjectRequestId}
          />
          <div className="project-create__title">{project.name}</div>
          {editProjectOverviewFormActive ? (
            <ProjectOverviewForm
              project={project}
              collaborators={collaborators || []}
              onCancel={() => setEditProjectOverviewFormActive(false)}
              onProjectSave={handleEditProjectOverview}
              saveLoading={loading}
            />
          ) : (
            <ProjectOverviewInfo
              project={project}
              collaborators={collaborators}
              onEdit={() => setEditProjectOverviewFormActive(true)}
            />
          )}
          <div className="project-edit__advanced-settings">
            <Accordion>
              <AccordionItem
                id="advanced-settings"
                title="Advanced Settings"
                ariaLabel="Advanced Settings"
              >
                {project.moduleType === ProjectModuleType.Hiring ? (
                  <>
                    <ProjectReportsInfo />
                    <ProjectReportsForm project={project} />
                  </>
                ) : null}
                {editProjectLanguageFormActive ? (
                  <ProjectLanguageForm
                    project={project}
                    onCancel={() => setEditProjectLanguageFormActive(false)}
                    onProjectSave={handleEditProjectLocales}
                    saveLoading={loading}
                  />
                ) : (
                  <ProjectLanguageInfo
                    project={project}
                    onEdit={() => setEditProjectLanguageFormActive(true)}
                  />
                )}

                {project.company && (
                  <ProjectAuthenticationSettings
                    projectId={parsedProjectId}
                    companyId={project.company.id}
                  />
                )}
              </AccordionItem>
            </Accordion>
          </div>

          {project.company && (
            <ProjectJobRolesOverview
              projectId={project.id}
              companyId={project.company.id}
            />
          )}
          {project.company && (
            <Stage
              projectId={project.id}
              reportsAccess={project.company.managerReportEmailLinks}
            />
          )}
        </div>
      </div>
    );
  }

  return null;
};

export default ProjectEdit;
