import OpenCompanyAppButton from 'components/molecules/OpenCompanyAppButton/OpenCompanyAppButton';
import ProjectInfoItem from 'components/molecules/ProjectnfoItem/ProjectInfoItem';
import {
  ActorRole,
  ProjectCollaborator,
  ProjectFragment,
  useStageFindManyQuery,
} from 'generated/graphql';
import {
  collaboratorsByRole,
  formatCamelCase,
  formatProjectDate,
} from 'helpers/project';
import { IProjectPath } from 'interfaces/routes';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  InlineNotification,
  NotificationType,
  Tooltip,
  useLocales,
} from '@spotted-zebra-uk/ui-components';

interface IProjectInfo {
  project: ProjectFragment;
  collaborators?: Array<ProjectCollaborator> | null;
  onEdit: () => void;
}

const ProjectCollaboratorItem = ({
  label,
  collaborators,
}: {
  label: string;
  collaborators: Array<ProjectCollaborator>;
}) => {
  return collaborators.length ? (
    <div className="project-info__item">
      <div className="project-info__label">{label}</div>
      <div className="project-info__value">
        {collaborators.map((collaborator, index) => (
          <Tooltip key={collaborator.id} content={collaborator.user?.email}>
            <span className="project-info__tooltip-content">
              {collaborator.user?.firstName} {collaborator.user?.lastName}
              {index === collaborators.length - 1 ? '' : ', '}
            </span>
          </Tooltip>
        ))}
      </div>
    </div>
  ) : null;
};

export const ProjectOverviewInfo: FC<IProjectInfo> = ({
  project,
  collaborators,
  onEdit,
}) => {
  const { projectId } = useParams() as IProjectPath;
  const { data: stagesData, loading } = useStageFindManyQuery({
    variables: { projectId: Number(projectId) },
    onError: error => {
      console.log(error);
    },
  });

  const generalSectionInfo = [
    { label: 'Company', value: project.company?.name },
    {
      label: 'Product solution',
      value: formatCamelCase(project.productSolution),
    },
    {
      label: 'Product module type',
      value: formatCamelCase(project.moduleType),
    },
  ];

  const unassigned = collaborators?.filter(
    collaborator => !collaborator.actorRole
  );
  const byRole = collaboratorsByRole(collaborators || []);

  const isCompanyAppButtonDisabled = useMemo(() => {
    const stages = stagesData?.Stages;
    return !(stages && stages.length > 0);
  }, [stagesData]);

  const handleOpenProjectOnCompanyApp = () => {
    const projectUrl = `${process.env.REACT_APP_COMPANY_APP_BASE_URL}/project/${projectId}`;
    window.open(projectUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <div>
      <div className="project-header">
        <div className="project-header__left">
          <div className="project-header__title">Project overview</div>
        </div>

        <div className="project-header__right">
          <OpenCompanyAppButton
            onClick={handleOpenProjectOnCompanyApp}
            disabled={isCompanyAppButtonDisabled}
            loading={loading}
          />
          <Button onClick={onEdit} size="medium">
            Edit
          </Button>
        </div>
      </div>

      <div className="project-info project-info__indent">
        <div className="project-info__details">
          <ProjectInfoItem
            label="Created"
            value={formatProjectDate(project.createdAt)}
          />
          <ProjectInfoItem
            label="Edited"
            value={formatProjectDate(project.updatedAt)}
          />
        </div>
        <h3 className="project-info__title">General</h3>
        {generalSectionInfo.map(({ label, value }) => (
          <ProjectInfoItem key={label} label={label} value={value} />
        ))}

        <h3 className="project-info__title">Project team</h3>
        {collaborators && !collaborators.length ? (
          <InlineNotification
            notificationType={NotificationType.WARNING}
            className="project-info__warning"
          >
            <p>
              Add users to the project team to make sure they can access the
              project.
            </p>
          </InlineNotification>
        ) : null}

        {unassigned?.length ? (
          <InlineNotification
            title="Missing Information"
            notificationType={NotificationType.WARNING}
            className="project-info__warning"
          >
            <p>
              Edit the project overview to tag project roles for these project
              team members:
            </p>
            <p className="project-info__emphasis">
              <>
                {unassigned.map((collaborator, index) => (
                  <Tooltip
                    key={collaborator.id}
                    content={collaborator.user?.email}
                  >
                    <span className="project-info__tooltip-content">
                      {collaborator.user?.firstName}{' '}
                      {collaborator.user?.lastName}
                      {index === unassigned.length - 1 ? '' : ', '}
                    </span>
                  </Tooltip>
                ))}
              </>
            </p>
          </InlineNotification>
        ) : null}

        <ProjectCollaboratorItem
          label="Hiring manager"
          collaborators={byRole[ActorRole.HiringManager] || []}
        />
        <ProjectCollaboratorItem
          label="Recruiter"
          collaborators={
            byRole[ActorRole.RecruiterOrTalentAcquisitionPartner] || []
          }
        />
        <ProjectCollaboratorItem
          label="Interviewer"
          collaborators={byRole[ActorRole.Interviewer] || []}
        />
        <ProjectCollaboratorItem
          label="Reskilling team member"
          collaborators={byRole[ActorRole.ReskillingTeamMember] || []}
        />
        <ProjectCollaboratorItem
          label="Line manager"
          collaborators={byRole[ActorRole.LineManager] || []}
        />
        <ProjectCollaboratorItem
          label="Early careers team member"
          collaborators={byRole[ActorRole.EarlyCareerTeamMember] || []}
        />
        <ProjectCollaboratorItem
          label="Head of People/Talent"
          collaborators={byRole[ActorRole.HeadOfPeopleOrTalent] || []}
        />
      </div>
    </div>
  );
};

export const ProjectLanguageInfo: FC<IProjectInfo> = ({ project, onEdit }) => {
  const { getSupportedLocaleMetadata, SupportedLocale } = useLocales();
  return (
    <div>
      <div className="project-header">
        <div className="project-header__left">
          <div className="project-header__sub-title">Project languages</div>
        </div>

        <div className="project-header__right">
          <Button onClick={onEdit} size="medium">
            Edit
          </Button>
        </div>
      </div>

      <div className="project-info">
        <div className="project-info__item">
          <div className="project-info__label">Selected</div>
          <div className="project-info__value">
            {project.locales
              .map(
                locale =>
                  getSupportedLocaleMetadata(SupportedLocale[locale]).name
                    .english
              )
              .join(', ')}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProjectReportsInfo: FC = () => {
  return (
    <div>
      <div className="project-header">
        <div className="project-header__left">
          <div className="project-header__sub-title">Reports</div>
        </div>
      </div>
    </div>
  );
};

export default ProjectOverviewInfo;
